import React, { Component } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import RichTextConverter from '../components/atoms/RichTextConverter';
import Img from 'gatsby-image';
import PreHead from '../components/molecules/PreHead';
import SEO from '../components/molecules/SEO';
import CenteredPage from '../components/organisms/CenteredPage';

const ImageHolder = styled.div`
  width: 100%;
`;

class News extends Component {
  render() {

    const { contentfulNews } = this.props.data;
    const { title, content, image, excerpt, date } = contentfulNews;

    return (
      <>
        <SEO title={title} description={excerpt}/>
        <PreHead image="hero_00003.jpg"/>
        <CenteredPage>
            {image && <ImageHolder><Img fluid={image.fluid}/></ImageHolder>}
            <h1>{title}</h1>
            <small>{date}</small>
          {content && <section><RichTextConverter content={content.json}/></section>}
        </CenteredPage>
      </>
    )
  }
}

export default News;

export const pageQuery = graphql`
    query($id: String!) {
        contentfulNews(id: { eq: $id }) {
            title
            date(formatString: "DD.MM.YY")
            excerpt
            image {
                fluid(maxWidth: 1000) {
                    sizes
                    src
                    srcSet
                    aspectRatio
                }
            }
            createdAt(formatString: "DD.MM.YY")
            content {
                json
            }
        }
    }
`;
